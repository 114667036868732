<template>
  <div>
    <quill-editor
      :options="editorOption"
      :Content="Content"
      @change="onEditorChange($event)"
      v-model="content"
      ref="quillEditor"
    >
    </quill-editor>
  </div>
</template>

<script>

import hljs from 'highlight.js'
import "highlight.js/styles/github-dark.min.css"

import Quill from 'quill'
import { quillEditor } from "vue-quill-editor"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"

import imageResize from 'quill-image-resize-module'  //添加
Quill.register('modules/imageResize', imageResize)  //添加

import "@/assets/js/quill-title"
import {addQuillTitle} from "@/assets/js/quill-title";

export default {
  name: "editor",
  data(){
    return {
      content: "",
      // 富文本编辑器配置
      editorOption: {
        modules: {
          //代码高亮,但有bug
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          },
          //图片重置大小
          imageResize: {   //添加
            displayStyles: {   //添加
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']   //添加
          },
          //工具栏
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],        // 文本样式
              ['blockquote', 'code-block'],                    // 块引用和代码块
              [{'list': 'ordered'}, {'list': 'bullet'}],    // 有序列表和无序列表
              [{'script': 'sub'}, {'script': 'super'}],      // 上下标
              [{'indent': '-1'}, {'indent': '+1'}],          // 缩进
              [{'direction': 'rtl'}],                         // 文本方向
              [{'size': ['small', false, 'large', 'huge']}], // 字体大小
              [{'header': [1, 2, 3, 4, 5, 6, false]}],       // 标题
              [{'color': []}, {'background': []}],          // 文本颜色和背景颜色
              [{'font': []}],                                 // 字体
              [{'align': []}],                                // 对齐方式
              ['clean'],                                        // 清除格式
              ['link', 'image', 'video']                        // 插入链接、图片和视频
            ],
            handlers: {
             image: (value) => {
                if (value) {
                  // 这是是为了获取你当前点击的那个富文本框
                 // this.videoOrimg = true
                 // document.querySelectorAll('.upload-demo input')[0].click()
                  this.uploadImage(value)
                } else {
                  this.quill.format('image', false)
                }
              },
            },
          }
        },
      }
    }
  },
  //定义接受父组件传来的数据
  props:{
    Content:String,
  },
  methods:{
    //当编辑器内容变化事件
    onEditorChange(quill){
      this.content = quill.html
      this.$emit("EditorContent",this.content)
    },
    // 图片上传函数
    uploadImage(event) {
      console.log("event",event)
      let input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.click();

      input.onchange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        // 创建FormData对象
        const formData = new FormData();
        formData.append('file', file);

        try {
            // 发送文件到服务器
            const response =  this.$http.post(this.$http.defaults.baseURL+"/upload_file/index", formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':sessionStorage.getItem("token")
              }
            });

            // 假设服务器返回的url是response.data.url
            response.then(res=>{
              if (res.data.code==0){
                console.log(res.data)
                let url = res.data.data.url.substring(1)
                console.log(this.$siteUrl+url)
                this.insertImage(this.$siteUrl+url);
              }
            }).catch(error=>{
              console.error('上传图片失败:', error);
            })

        } catch (error) {
          console.error('上传图片失败:', error);
        }
        //this.insertImage("https://pss.bdstatic.com/static/superman/img/topnav/newzhidao-da1cf444b0.png")
      };
    },
    //将上传的图片插入当前光标后
    insertImage(url) {
      this.$refs.quillEditor.quill.insertEmbed(
        this.$refs.quillEditor.quill.getSelection().index,
        'image',
        url,
        0
      )
    }
  },
  components:{
    quillEditor
  },
  mounted() {
    addQuillTitle()
  },
  watch: {
    Content(val) {
       this.content = val
    }
  }
}
</script>

<style scoped>
.quill-editor {
  height: 200px;
}
</style>
